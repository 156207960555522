<template>
  <div class="header">
    <!-- Add Organization rich snippet -->
    <div class="hidden" itemscope itemtype="http://schema.org/Organization">
      <link itemprop="url" href="https://artykulygrzewcze.pl/">
      <div itemprop="contactPoint" itemscope itemtype="http://schema.org/ContactPoint">
        <span itemprop="telephone">+48 22 397 43 92</span>
        <span itemprop="email">sklep@artykulygrzewcze.pl</span>
        <span itemprop="contactType">customer service</span>
      </div>
    </div>
    <header
      class="fixed w-100 brdr-bottom-1 bg-cl-primary brdr-cl-secondary save-bottom"
      :class="[{ 'is-visible': navVisible }, { 'checkout-mode' : isCheckoutPage }]"
    >
      <div class="container-fluid cl-white bg-cl-alternative topbar-container">
        <LazyHydrate when-visible>
          <top-bar />
        </LazyHydrate>
      </div>
      <LazyHydrate when-visible>
        <inner-header />
      </LazyHydrate>
      <LazyHydrate when-visible>
        <desktop-menu class="hidden-xs-and-tablet" v-if="!isCheckoutPage" />
      </LazyHydrate>
    </header>
    <div class="header-placeholder bg-cl-white-smoke" :class="{ 'checkout-height' : isCheckoutPage }" />
  </div>
</template>

<script>
// import InnerHeader from 'theme/components/core/blocks/Header/InnerHeader.vue';
// import DesktopMenu from 'theme/components/core/blocks/Header/DesktopMenu';
// import TopBar from 'theme/components/core/blocks/Header/TopBar'
import { mapState } from 'vuex';
import CurrentPage from 'theme/mixins/currentPage';
import LazyHydrate from 'vue-lazy-hydration'

const TopBar = () => import(/* webpackChunkName: "vsf-top-bar" */ 'theme/components/core/blocks/Header/TopBar')
const DesktopMenu = () => import(/* webpackChunkName: "vsf-desktop-memu" */ 'theme/components/core/blocks/Header/DesktopMenu')
const InnerHeader = () => import(/* webpackChunkName: "vsf-inner-header" */ 'theme/components/core/blocks/Header/InnerHeader.vue')

export default {
  name: 'Header',
  components: {
    TopBar,
    LazyHydrate,
    InnerHeader,
    DesktopMenu
  },
  mixins: [CurrentPage],
  data () {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 84
    };
  },
  // created () {
  //   this.$store.dispatch('cmsBlock/list', {
  //     filterValues: null
  //   })
  // },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      currentUser: state => state.user.current
    }),
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    }
  },
  beforeMount () {
    window.addEventListener(
      'scroll',
      () => {
        this.isScrolling = true;
      },
      { passive: true }
    );
    setInterval(this.checkScroll, 250);
  },
  beforeDestroy () {
    clearInterval(this.checkScroll)
  },
  methods: {
    checkScroll () {
      if (this.isScrolling) {
        this.$bus.$emit('has-scrolled')
        this.hasScrolled()
        this.isScrolling = false
      }
    },
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup');
    },
    hasScrolled () {
      this.scrollTop = window.scrollY;
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false;
      } else {
        this.navVisible = true;
      }
      this.lastScrollTop = this.scrollTop;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-icon-hover: color(secondary, $colors-background);

.topbar-container {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
header {
  height: 90px;
  top: -90px;
  z-index: 4;
  transition: top 0.2s ease-in-out;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    height: 60px;
    top: unset;
    bottom: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  &.is-visible {
    top: 0;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
      top: unset;
    }
  }
}
.icon {
  opacity: 0.6;
  &:hover,
  &:focus {
    background-color: $color-icon-hover;
    opacity: 1;
  }
}
.header-placeholder {
  height: 145px;
  &.checkout-height {
    height: 43px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
      height: 43px;
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    height: 76px;
  }
}
.checkout-mode {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    height: 46px;
    box-shadow: none;
    padding-bottom: unset !important;
  }
  height: auto;
  top: 0 !important;
}
</style>
